import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { TYPE_HELPER, TYPE_PERSONAL } from "common/User";
import { TYPE_ORGANIZATION } from "components/Common/User";
import Langs from "components/Const/Lang";
import LocationFilterModal from "components/FilterForm/components/LocationFilterModal";
import React from "react";

const NewsFilterForm = ({ formik, lang = "vi", query }) => {
  return (
    <>
      {/* <FormControl fullWidth sx={{ marginBottom: "8px" }}>
        <InputLabel htmlFor="creatorName" size="small">
          {Langs?.ten_to_chuc_manh_thuong_quan?.[lang]}
        </InputLabel>
        <OutlinedInput
          id="creatorName"
          name="creatorName"
          value={formik?.values?.creatorName ?? query?.creatorName}
          onChange={formik?.handleChange}
          error={
            formik?.touched?.creatorName && Boolean(formik?.errors?.creatorName)
          }
          size="small"
          className="pb-1"
          label={Langs?.ten_to_chuc_manh_thuong_quan?.[lang]}
        />
        <FormHelperText style={{ color: "red" }}>
          {formik?.errors?.creatorName}
        </FormHelperText>
      </FormControl> */}

      <LocationFilterModal formik={formik} lang={lang} />

      <FormControl fullWidth sx={{ marginBottom: "8px" }}>
        <InputLabel id="sortedBy-label" size="small">
          {Langs?.sap_xep_theo?.[lang]}
        </InputLabel>
        <Select
          variant="outlined"
          name="sortedBy"
          id="sortedBy"
          select
          value={formik?.values?.sortedBy ?? ""}
          onChange={formik?.handleChange}
          size="small"
          error={formik?.touched?.sortedBy && Boolean(formik?.errors?.sortedBy)}
          input={<OutlinedInput label={`${Langs?.sap_xep_theo?.[lang]}`} />}
          labelId="sortedBy-label"
          sx={{ backgroundColor: "#fff" }}
        >
          <MenuItem value={'[{"key":"STARTED_AT","reverse":true}]'}>
            {Langs?.tin_moi_nhat?.[lang]}
          </MenuItem>
          <MenuItem value={'[{"key":"STARTED_AT","reverse":false}]'}>
            {Langs?.tin_cu_nhat?.[lang]}
          </MenuItem>
        </Select>
        <FormHelperText sx={{ margin: "3px 0 0 0" }} style={{ color: "red" }}>
          {formik?.touched?.sortedBy && formik?.errors?.sortedBy}
        </FormHelperText>
      </FormControl>

      {/* <FormControl fullWidth sx={{ marginBottom: "8px" }}>
                  <InputLabel htmlFor="title" size="small">Tiêu đề bài viết</InputLabel>
                  <OutlinedInput
                    id="title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    size="small"
                    className="pb-1"
                    label="Tiêu đề bài viết"
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {formik.errors.title}
                  </FormHelperText>
                </FormControl> */}

      {/* <FormControl fullWidth>
                  <CitySelect formik={formik} />
                  <FormHelperText style={{ color: "red" }}>
                    {formik.errors.cityId}
                  </FormHelperText>
                </FormControl> */}

      {/* <Stack spacing={2} direction="row" sx={{ marginBottom: "8px" }}>
                  <FormControl variant="standard" sx={{ width: "100%" }} >
                    <DatePickerCustom
                      formik={formik}
                      id="createdFrom"
                      name="createdFrom"
                      maxDate={formik.values.createdTo}
                      error={
                        formik.touched.createdFrom &&
                        Boolean(formik.errors.createdFrom)
                      }
                      size="small"
                      className="pb-1"
                      placeholder="Từ ngày"
                    />
                    <FormHelperText style={{ color: "red" }}>
                      {formik.errors.createdFrom}
                    </FormHelperText>
                  </FormControl>

                  <FormControl variant="standard" sx={{ width: "100%", marginBottom: "8px" }} >
                    <DatePickerCustom
                      formik={formik}
                      id="createdTo"
                      name="createdTo"
                      minDate={formik.values.createdFrom}
                      error={
                        formik.touched.createdTo &&
                        Boolean(formik.errors.createdTo)
                      }
                      size="small"
                      className="pb-1"
                      placeholder="Đến ngày"
                    />
                    <FormHelperText style={{ color: "red" }}>
                      {formik.errors.createdTo}
                    </FormHelperText>
                  </FormControl>
                </Stack> */}
    </>
  );
};

export default NewsFilterForm;
