import { Global } from "@emotion/react";
import {
  Alert,
  Badge,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  NoSsr,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "components/CustomButton";
import { FilterIcon } from "components/Icons";
import Swiper from "react-id-swiper";
import { globalStyles } from "../../styles/stylesConfig.ts";
import _ from "lodash";
import Langs from "components/Const/Lang";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { renderURL } from "utils";
import {
  CATE_GIVE,
  CATE_PUBLIC,
  CATE_TAKE,
  TYPE_NEWS,
} from "components/Common/Topic";
import { useFormik } from "formik";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Paper, useMediaQuery } from "@mui/material";
import { TYPE_ORGANIZATION } from "components/Common/User";
import { TYPE_HELPER } from "common/User";
import qs from "query-string";
import CloseIcon from "@mui/icons-material/Close";
import cookies from "next-cookies";
import { Lan } from "@mui/icons-material";
import UseMobileDetect from "components/Hook/UseMobileDetect";
import moment from "moment-mini";

const useStyles = makeStyles((Theme) =>
  createStyles({
    input: {
      height: 30,
      marginTop: Theme.spacing(0.6),
      backgroundColor: "#fff",
      boxShadow: "2px 2px 2px #ddd",
      fontSize: "14px",
      paddingLeft: "0px !important",
    },
    label: {
      fontSize: "13px",
      lineHeight: "1rem",
      top: "-2px",
      shrink: false,
    },
  })
);

const params = {
  slidesPerView: "auto",
  pagination: {
    el: ".swiper-pagination",
    clickable: false,
  },
};

const FilterSubMenu = ({
  arrFilter,
  openModal,
  filter,
  isNews,
  categoryId,
  labelTextFilter,
  keyTextFilter,
  memberTypeKey,
  directLink,
  lang = "vi",
}) => {
  const router = useRouter();
  const matches = useMediaQuery("(min-width:1024px)");
  let detectDevice = UseMobileDetect();
  const pathname = router?.pathname;
  const [objAlert, setObjAlert] = useState({});
  const gps = cookies("gps")?.gps || {};
  const version = cookies("version")?.version || "";
  const initFilter = {
    isAvailable: categoryId === TYPE_NEWS?.id ? undefined : 1, // 1: bai viet chua het han
    categoryId: categoryId,
    types:
      categoryId === TYPE_NEWS?.id
        ? `[${CATE_PUBLIC?.id}]`
        : `[${CATE_GIVE?.id}, ${CATE_TAKE?.id}]`,
    memberTypes:
      categoryId === TYPE_NEWS?.id
        ? `[${TYPE_ORGANIZATION?.id},${TYPE_HELPER?.id}]`
        : undefined,
    allPage: true,
    // page: 1,
    // size: 100,
  };
  const classes = useStyles();

  useEffect(() => {
    formik.setFieldValue([keyTextFilter], router?.query?.[keyTextFilter]);
  }, [router?.query?.[keyTextFilter]]);

  const renderFilterBadgeContent = (_filter) => {
    if (_filter.categoryId * 1 !== TYPE_NEWS?.id) delete _filter[keyTextFilter];
    if (_filter.categoryId * 1 === TYPE_NEWS?.id || !_filter.categoryId)
      delete _filter.memberTypes;
    delete _filter.type;
    delete _filter.page;
    delete _filter.size;
    delete _filter.categoryId;
    delete _filter.isAvailable;
    delete _filter.memberType;
    delete _filter.types;
    delete _filter.allPage;
    delete _filter.addressLocation;
    delete _filter.creatorName;
    delete _filter.update;

    return _filter;
  };

  const checkQuery = (item) => {
    let clone = _.cloneDeep(filter);
    if (isNews) {
      let memberTypes =
        router?.query?.[memberTypeKey] &&
        JSON.parse(router?.query?.[memberTypeKey]);
      if (memberTypes?.length === 1 && memberTypes.includes(item?.type)) {
        clone[memberTypeKey] = `[${TYPE_ORGANIZATION?.id},${TYPE_HELPER?.id}]`;
        return { ...clone };
      } else {
        return { ...filter, [memberTypeKey]: `[${item?.type}]` };
      }
    } else {
      if (router?.query?.type * 1 !== item?.type) {
        return { ...filter, type: item?.type };
      } else {
        delete clone.type;
        return { ...clone };
      }
    }
  };

  const renderFilter = () => {
    return arrFilter.map((item, index) => {
      return (
        <CustomButton
          key={index}
          onClick={() =>
            router.push({
              pathname: item?.pathname,
              query: checkQuery(item),
            })
          }
          bgColor={
            filter?.type * 1 === item?.type * 1 ||
            (filter?.[memberTypeKey] &&
              JSON.parse(filter?.[memberTypeKey])?.length === 1 &&
              JSON.parse(filter?.[memberTypeKey])?.includes(item?.type))
              ? "red"
              : "white"
          }
          colorLevel={"200"}
          hoverLevel={"200"}
          size="small"
          sx={{
            color:
              filter?.type * 1 === item?.type * 1 ||
              (filter?.[memberTypeKey] &&
                JSON.parse(filter?.[memberTypeKey])?.length === 1 &&
                JSON.parse(filter?.[memberTypeKey])?.includes(item?.type))
                ? "#fff"
                : "#333",
            width: "auto",
            minWidth: "30px",
            height: "30px",
            fontWeight: 400,
            textTransform: "inherit",
            borderRadius: "4px",
            fontSize: "14px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          variant="contained"
        >
          {item?.name}
        </CustomButton>
      );
    });
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: { [keyTextFilter]: filter?.[keyTextFilter] },
    onSubmit: (values) => {},
  });

  const searchText = () => {
    let query = router?.query;
    if (formik?.values?.[keyTextFilter]?.length > 0) {
      query[keyTextFilter] = formik?.values?.[keyTextFilter].trim() + "";
    } else {
      delete query[keyTextFilter];
    }
    router.push(
      `${directLink}${
        Object.keys(query).length > 0 ? "?" + qs.stringify(query) : ""
      }`
    );
  };

  const clearText = () => {
    let query = router?.query;
    delete query[keyTextFilter];
    formik.setFieldValue([keyTextFilter], "");
    router.push(
      `${directLink}${
        Object.keys(query).length > 0 ? "?" + qs.stringify(query) : ""
      }`
    );
  };

  useEffect(() => {
    const handleReceivedMessage = (event) => {
      if (event?.data) {
        let data = JSON.parse(event.data);
        if (data?.GET_GPS) {
          document.cookie = `gps=${JSON.stringify(data.GET_GPS)}; path=/;`;
          router.push({
            pathname: "/map",
            query: renderURL(
              "",
              directLink === "/community"
                ? {
                    ...initFilter,
                    isAvailable: undefined,
                    categoryId: undefined,
                    types: `[${TYPE_ORGANIZATION?.id},${TYPE_HELPER?.id}]`,
                  }
                : initFilter
            ),
          });
        }
      }
    };

    var iOS =
      !!navigator.platform &&
      /iPad|iPhone|iPod|MacIntel/.test(navigator.platform);
    var android =
      navigator.userAgent && navigator.userAgent.includes("Android");

    // document.cookie = "native=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT"

    if (iOS) {
      window.addEventListener("message", handleReceivedMessage);
    }
    if (android) {
      document.addEventListener("message", handleReceivedMessage);
    }
    return () => {
      if (iOS) {
        window.removeEventListener("message", handleReceivedMessage);
      }
      if (android) {
        document.removeEventListener("message", handleReceivedMessage);
      }
    };
  }, []);

  const goToMapPage = () => {
    // là app -> gửi message để vào trang cài đặt trên app
    // if (detectDevice.isMobile()) {
    if (version?.length > 0) {
      window.ReactNativeWebView &&
        window.ReactNativeWebView?.postMessage(
          JSON.stringify("CHECK_PERMISSION_LOCATION")
        );
    } else if (navigator.geolocation && navigator.permissions) {
      // nếu là web -> kiểm tra quyền
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          // nếu quyền bị từ chối -> báo alert và xóa cookies gps
          if (result.state === "denied") {
            setObjAlert({
              type: "warning",
              text: Langs?.ban_can_cap_quyen?.[lang],
            });

            document.cookie =
              "gps={}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
          } else {
            // yêu cầu quyền
            navigator.geolocation.getCurrentPosition(showPosition);
          }
        });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  function showPosition(position) {
    let location = {
      x: position?.coords?.latitude,
      y: position?.coords?.longitude,
    };

    document.cookie = `gps=${JSON.stringify(
      location
    )}; path=/; expires=${moment().add(1, "day").toString()}`;

    router.push({
      pathname: "/map",
      query: renderURL(
        "",
        directLink === "/community"
          ? {
              ...initFilter,
              isAvailable: undefined,
              categoryId: undefined,
              types: `[${TYPE_ORGANIZATION?.id},${TYPE_HELPER?.id}]`,
            }
          : initFilter
      ),
    });
  }

  return (
    <Box sx={{ marginTop: "10px" }}>
      {objAlert && Object.keys(objAlert)?.length > 0 && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={objAlert && Object.keys(objAlert)?.length > 0}
          autoHideDuration={6000}
          onClose={() => setObjAlert({})}
        >
          <Alert severity={objAlert?.type} sx={{ width: "100%" }}>
            {objAlert?.text}
          </Alert>
        </Snackbar>
      )}

      <Grid container spacing={1} mb={1}>
        <Grid item xs>
          <NoSsr>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                id={keyTextFilter}
                name={keyTextFilter}
                // value={formik?.values?.[keyTextFilter]}
                value={
                  formik?.values?.[keyTextFilter] ??
                  router?.query?.[keyTextFilter]
                }
                onChange={formik?.handleChange}
                error={
                  formik?.touched?.[keyTextFilter] &&
                  Boolean(formik?.errors?.[keyTextFilter])
                }
                label={
                  formik?.values?.[keyTextFilter]?.length > 0
                    ? ""
                    : labelTextFilter
                }
                InputLabelProps={{ shrink: false }}
                variant="outlined"
                // sx={{
                //     "& .MuiInputLabel-root": { lineHeight: "0.7rem", fontSize: "14px", top: "-3px" },
                //     "& .MuiOutlinedInput-input": { height: "2rem", padding: "0 14px", marginTop: "0" }
                // }}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      {formik?.values?.[keyTextFilter]?.length > 0 && (
                        <IconButton
                          aria-label="clear text"
                          onClick={clearText}
                          edge="end"
                        >
                          <CloseIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                      )}
                      <IconButton
                        aria-label="search text"
                        onClick={searchText}
                        edge="end"
                      >
                        <SearchIcon sx={{ fontSize: "16px" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  className: classes.label,
                  shrink: false,
                }}
                fullWidth
              />
            </form>
          </NoSsr>
        </Grid>
        <Grid item sx={{ width: matches ? "90px" : "50px", marginTop: "4px" }}>
          <Badge
            badgeContent={
              Object.keys(renderFilterBadgeContent(_.cloneDeep(filter))).length
            }
            color="primary"
          >
            <CustomButton
              bgColor="white"
              size="small"
              variant="contained"
              sx={{
                borderRadius: "4px",
                padding: matches ? "0 0 0 5px" : "0",
                textTransform: "inherit",
                height: "30px",
                width: matches ? "80px" : "40px",
                minWidth: matches ? "80px" : "40px",
                textAlign: "center",
              }}
              onClick={() => openModal()}
            >
              {matches && <span>{Langs?.bo_loc?.[lang]}</span>}{" "}
              <FilterIcon aria-label="filter" sx={{ fontSize: "1.6rem" }} />
            </CustomButton>
          </Badge>
        </Grid>
        <Grid item sx={{ width: matches ? "130px" : "50px", marginTop: "4px" }}>
          {/* <Swiper {...params}> */}
          <CustomButton
            bgColor="white"
            size="small"
            variant="contained"
            sx={{
              borderRadius: "4px",
              padding: "0 5px",
              textTransform: "inherit",
              height: "30px",
              width: matches ? "120px" : "40px",
              minWidth: matches ? "120px" : "40px",
              textAlign: "center",
            }}
            onClick={goToMapPage}
          >
            {matches && <span>{Langs?.tim_quanh_toi?.[lang]}</span>}{" "}
            <LocationOnIcon
              aria-label="location-gps"
              sx={{ fontSize: "1.2rem" }}
            />
          </CustomButton>
          {/* </Swiper> */}
        </Grid>
      </Grid>

      {renderFilter()}
      {/* <Global styles={globalStyles} /> */}
    </Box>
  );
};

export default FilterSubMenu;
