import CloseIcon from "@mui/icons-material/Close";
import { Container, Grid, Modal, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import { getArticles } from "actions/homeAPI";
import classnames from "classnames";
import { TYPE_HELPER } from "common/User";
import { Header } from "components";
import Article from "components/Article";
import { CATE_PUBLIC, TYPE_NEWS } from "components/Common/Topic";
import { TYPE_ORGANIZATION, TYPE_STRONG_SPONSOR } from "components/Common/User";
import Langs from "components/Const/Lang";
import FilterForm from "components/FilterForm";
import FilterSubMenu from "components/FilterSubMenu";
import Footer from "components/Footer";
import LoadMore from "components/Hook/LoadMore";
import PaginationCom from "components/PaginationCom";
import moment from "moment-mini";
import cookies from "next-cookies";
import { useRouter } from "next/router";
import qs from "query-string";
import { useEffect, useMemo, useState } from "react";
import { renderURL } from "utils";
import styles from "../styles/Home.module.css";
import NewsFilterForm from "./components/NewsFilterForm";

const defaultImg =
  process.env.NEXT_PUBLIC_MEDIA_API + process.env.NEXT_PUBLIC_DEFAULT_THUMB;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function Home() {
  const [visible, setVisible] = useState(false);
  const router = useRouter();
  const [isReady, setIsReady] = useState(false);
  const authCookie = cookies("auth")?.auth || {};
  const token = cookies("token")?.token || "";
  const gps = cookies("gps")?.gps || {};
  const lang =
    cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;

  const arrFilter = [
    {
      name: TYPE_ORGANIZATION?.[lang],
      type: TYPE_ORGANIZATION?.id, // 2
    },
    {
      name: TYPE_STRONG_SPONSOR?.[lang],
      type: TYPE_STRONG_SPONSOR?.id, //3
    },
  ];

  const filter = useMemo(() => {
    return {
      ...router.query,
      title: router?.query?.title ? router.query.title : undefined,
      creatorName: router?.query?.creatorName?.toString() ?? undefined,
      memberTypes: router?.query?.memberTypes
        ? router.query.memberTypes
        : `[${TYPE_ORGANIZATION?.id},${TYPE_HELPER?.id}]`,
      categoryId: TYPE_NEWS?.id, // 1: id news api quan ly
      sortedBy: router.query?.sortedBy ? router.query.sortedBy : undefined,
      cityId: router.query?.cityId ? router.query.cityId : undefined,
      districtId: router.query?.districtId
        ? router.query.districtId
        : undefined,
      types: `[${CATE_PUBLIC?.id}]`,
    };
  }, [router.query]);

  const clientAuthCookie = cookies("auth")?.auth ?? authCookie ?? {};

  useEffect(() => {
    if (router.isReady) {
      setIsReady(true);
      router.push({
        pathname: router?.pathname,
        query: qs.stringify(filter),
      });
    }
  }, [router.isReady]);

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };

  return (
    <div className={classnames("app", styles.container)}>
      <Header authCookie={clientAuthCookie} token={token} isDisplaySub />
      <Container
        maxWidth="md"
        sx={{
          padding: "15px 20px 100px 20px !important",
          minHeight: "calc(100vh - 195px)",
        }}
      >
        <FilterSubMenu
          filter={router.query}
          arrFilter={arrFilter}
          openModal={openModal}
          isNews={true}
          categoryId={TYPE_NEWS?.id}
          labelTextFilter={Langs?.ten_to_chuc_manh_thuong_quan?.[lang]}
          keyTextFilter={"creatorName"}
          directLink="/"
          memberTypeKey={"memberTypes"}
          lang={lang}
        />
        {isReady && <LoadMore url="/posts" link="/community" />}
      </Container>
      <Footer />
      <Modal
        open={visible}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <div style={{ textAlign: "right" }}>
            <CloseIcon onClick={closeModal} sx={{ marginLeft: "auto" }} />
          </div>
          <FilterForm
            visible={visible}
            closeModal={closeModal}
            filter={{ ...filter }}
            isNews={true}
            lang={lang}
          >
            {(formik) => (
              <NewsFilterForm
                formik={formik}
                lang={lang}
                query={router?.query}
              />
            )}
          </FilterForm>
        </Box>
      </Modal>
    </div>
  );
}
