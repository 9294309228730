import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Langs from "components/Const/Lang";
import moment from "moment";
import cookies from "next-cookies";
import React, { useEffect, useState } from "react";
import { UAParser } from "ua-parser-js";

const Footer = () => {
  const parser = new UAParser();
  const browser = parser?.getBrowser();
  const engine = parser?.getEngine();
  const device = parser?.getDevice();
  const mdWidth = useMediaQuery("(min-width:769px)");
  const lang =
    cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;
  const version = cookies("version")?.version || "";
  const [displayFooter, setDisplayFooter] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDisplayFooter(true);
    }, 2000);
  }, []);

  return (
    <>
      {displayFooter ? (
        <>
          {/* khong co version la dang vao bang web + width > 768px -> hien thi footer desktop */}
          {version?.length === 0 && mdWidth ? (
            <Box
              sx={{
                backgroundColor: "#fff9f9",
                padding: "20px 0",
              }}
            >
              <Container maxWidth="md" sx={{ padding: 0 }}>
                <Grid container spacing={2} fullWidth>
                  <Grid item xs={3}>
                    <Stack direction="row" spacing={1}>
                      <img src={"/logo-footer.svg"} width={50} height={50} />
                      <Stack direction="column">
                        <Typography
                          variant="body2"
                          sx={{ color: "#f47376", fontWeight: "bold" }}
                        >
                          {Langs?.ket_noi_yeu_thuong?.[lang]}
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <a
                            href="https://apps.apple.com/us/app/kết-nối-yêu-thương/id1600194119"
                            target="_blank"
                          >
                            <img
                              src={"/app-store.svg"}
                              width={30}
                              height={30}
                            />
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.estuary.charity.pro"
                            target="_blank"
                          >
                            <img
                              src={"/playstore.svg"}
                              width={26}
                              height={26}
                              style={{ marginTop: "2px" }}
                            />
                          </a>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={
                      lang === process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE ? 5 : 6
                    }
                    style={{ fontSize: "10px", paddingTop: "20px" }}
                  >
                    <p style={{ margin: "0" }}>
                      WEEWOO.VN -{Langs?.dien_dan_trao_doi?.[lang]}
                    </p>
                    <p style={{ margin: "0" }}>
                      {Langs?.chiu_trach_nhiem_noi_dung?.[lang]}
                    </p>
                    <p style={{ margin: "0" }}>
                      {Langs?.giay_phep_thiet_lap_mang_xa_hoi?.[lang]}
                    </p>
                    <p style={{ margin: "0" }}>
                      {
                        Langs?.giay_phep_thiet_lap_mang_xa_hoi_ngay_thang?.[
                          lang
                        ]
                      }
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={
                      lang === process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE ? 4 : 3
                    }
                    style={{ fontSize: "10px" }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "#f47376", fontWeight: "bold" }}
                    >
                      {Langs?.thong_tin_dich_vu?.[lang]}
                    </Typography>
                    <p style={{ margin: "0" }}>
                      {Langs?.email_footer_chiu_trach_nhiem?.[lang]}
                    </p>
                    <p style={{ margin: "0" }}>
                      <a
                        href={`${process.env.NEXT_PUBLIC_LINK}term`}
                        target="_blank"
                      >
                        {Langs?.dieu_khoan_chuong_trinh_footer?.[lang]}
                      </a>{" "}
                      -{" "}
                      <a
                        href={`${process.env.NEXT_PUBLIC_LINK}privacy`}
                        target="_blank"
                      >
                        {Langs?.chinh_sach_du_lieu_footer?.[lang]}
                      </a>
                    </p>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          ) : (
            <Stack
              direction="column"
              justifyContent="flex-end"
              className="text-center text-footer"
            >
              <Typography variant="subtitle2">
                Kết nối yêu thương @{moment().year()}
              </Typography>
              <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
                Version {version}
              </Typography>
            </Stack>
          )}
        </>
      ) : null}
    </>
  );
};

export default Footer;
